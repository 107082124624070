// React imports

import { useEffect, useRef, useState } from 'react'

// Remix imports

// Third party imports

// Generated imports

// App imports

const useFade = (show: boolean, duration: number) => {
  const [opacity, setOpacity] = useState(false)
  const [display, setDisplay] = useState(false)
  const showRef = useRef<any>(null)
  const hideRef = useRef<any>(null)

  useEffect(() => {
    if (show) {
      setDisplay(true)
      if (hideRef.current) {
        clearTimeout(hideRef.current)
      }
      showRef.current = setTimeout(() => {
        setOpacity(true)
      }, 50)
    }

    if (!show) {
      setOpacity(false)
      if (showRef.current) {
        clearTimeout(showRef.current)
      }
      hideRef.current = setTimeout(() => {
        setDisplay(false)
      }, duration)
    }

    return function cleanup() {
      if (hideRef.current) {
        clearTimeout(hideRef.current)
      }
      if (showRef.current) {
        clearTimeout(showRef.current)
      }
    }
  }, [show, duration])

  return { opacity, display }
}

export default useFade
